<template>
  <div>
    <NodePage v-if="node" v-bind="node" />
  </div>
</template>

<script lang="ts" setup>
import { NodePageFragment } from '#graphql-operations'

defineOptions({
  name: 'PageSlug',
})

definePageMeta({
  name: 'drupal-route',
  path: '/:slug(.*)*',
  languageMapping: {
    de: '/:slug(.*)*',
    fr: '/fr/:slug(.*)*',
    it: '/it/:slug(.*)*',
  },
  drupalRoute: true,
})

const nuxtRoute = useRoute()

const { data: query } = await useAsyncData(nuxtRoute.path, () =>
  useCachedGraphqlQuery('route', {
    path: nuxtRoute.path,
  }).then((v) => v.data),
)

const { entity: node } = await useDrupalRoute<NodePageFragment>(query.value)

setBreadcrumbLinksFromRoute(query.value)
setLanguageLinksFromRoute(query.value)
useRouteCache((c) => c.setCacheable().setMaxAge(600))
await renderPageDependencies()
</script>
